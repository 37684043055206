import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { PageMeta, PlasmicComponent } from "@plasmicapp/loader-react";
import { isEmpty } from "lodash";
import LottieLoader from "components/LottieLoader";
import { PLASMIC } from "constants/Plasmic";
import captureSentryError from "helper/captureSentryError";

const plasmicComponents = (ComponentName: string) => {
  return () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isPageExist, setIsPageExist] = useState(false);
    const [pageMetaData, setPageMetaData] = useState<PageMeta[]>([]);

    useEffect(() => {
      const initData = async () => {
        setIsLoading(true);
        try {
          const arrComponents = await PLASMIC.fetchPages();
          setPageMetaData(arrComponents);
          const plasmicPage = arrComponents.find(
            (item: any) => item.displayName === ComponentName
          );
          if (plasmicPage) {
            setIsLoading(false);
            setIsPageExist(true);
          } else {
            setIsLoading(false);
            setIsPageExist(false);
          }
        } catch (e) {
          captureSentryError(e)
          console.error(e);
          setIsLoading(false);
        }
      };
      if (isEmpty(ComponentName)) {
        setIsLoading(true);
      } else {
        initData();
      }
    }, []);

    const Loader = () => (
      <Box
        display={"flex"}
        flex={1}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <LottieLoader />
      </Box>
    );

    const renderPlasmic = () => {
      if (isLoading) {
        return <Loader />;
      } else if (isPageExist && !isEmpty(ComponentName)) {
        return <PlasmicComponent component={ComponentName} />;
      }
    };

    return <>{renderPlasmic()}</>;
  };
};

const Accessibility = plasmicComponents("Accessibility Statement");
const Cookies = plasmicComponents("Cookie Notice");
const FAQs = plasmicComponents("Explainer Videos");
const PrivacyPolicy = plasmicComponents("Privacy and Security Statement");
const PrivacyPolicyByEmployer = plasmicComponents("Privacy Statement from your Employer")
const TermsAndConditions = plasmicComponents("Terms and Conditions");
const SharedCostAvcCalculator = plasmicComponents("Shared Cost AVC Calculator");
const ExplainerVideos = plasmicComponents("Explainer Videos")
export {
  Accessibility,
  Cookies,
  FAQs,
  PrivacyPolicy,
  TermsAndConditions,
  SharedCostAvcCalculator,
  PrivacyPolicyByEmployer,
  ExplainerVideos
};
