import { values } from "lodash";

const PRODUCTS = {
  SNOOP: "snoop",
  INVESTMENT_ADVICE: "investment advice",
  MORTGAGE_SERVICE: "mortgage services",
  WILLSERVICE: "will services",
  AVCWISE: "avcwise",
  WEALTHIFY: "wealthify",
  NHS: "nhs",
  PENSION_TOP_UP: "pension top-up",
  RETIREMENT_WEALTH_MANAGEMENT:"retirement wealth management"
};

const PRODUCT_MAPPING: any = {
  snoop: "Snoop",
  investmentadvice: "Investment Advice",
  avcwise: "AVCWISE",
  mortgage: "Mortgage services",
  will: "Will services",
  wealthify: "Wealthify",
  nudge: "Nudge",
  nhs: "NHS",
  "top-up": "Pension Top-Up",
  "at-retirement":"Retirement Wealth Management"
};

const ORGANISATION_TYPE = {
  LGPS: "LGPS",
  NHS: "NHS",
  PRIVATE: "Private",
};

const TITLES_FOR_APPLICANTS = [
  {
    name: "Mr",
    value: "Mr",
  },
  {
    name: "Ms",
    value: "Ms",
  },
  {
    name: "Mrs",
    value: "Mrs",
  },
  {
    name: "Mx",
    value: "Mx",
  },
  {
    name: "Miss",
    value: "Miss",
  },
  {
    name: "Dr",
    value: "Dr",
  },
  {
    name: "Prof",
    value: "Prof",
  },
  {
    name: "Rev",
    value: "Rev",
  },
  {
    name: "Capt",
    value: "Capt",
  },
];

const AVAILABLE_GENDERS = [
  { name: "Male", label: "Male", value: "Male" },
  { name: "Female", label: "Female", value: "Female" },
];

const FLOAT_VALUE_REGEX = /^[0-9]+(\.[0-9]{1,2})?$/;
const PERCETAGE_VALUE_REGEX = /^(100|[1-9]?[0-9])(\.[0-9]+)?%?$/;

const ORGANISATION_DEFAULT_VALUES = {
  wage_sixteen: 4.81,
  wage_eighteen: 6.83,
  wage_twenty_one: 9.18,
  wage_twenty_three: 9.5,
};

const APP_PATHS = {
  MANAGE_PTU_PLAN: "/employee/privatePension/manageAvc",
  AMEND_PTU_PLAN: "/employee/privatePension/amendAvc",
  CANCEL_PTU_PLAN: "/employee/privatePension/cancelAvc",
  NO_SCHEME_PTU_PLAN: "/employee/privatePension/noScheme",
  PTU_APPLICATION_FORM: "/employee/privatePension/applicationForm",
  EMPLOYEE_PRODUCTS: "/employee/products",
  WEBINARS: "/employee/webinars/theme/list",
};

const PTU_PLAN_STATUS = {
  ACCEPTED: "Accepted",
  CANCELLED: "Cancelled",
  AWAITING_AUTHORISATION: "Awaiting Authorisation",
  ON_HOLD_NMW_ISSUE: "On Hold - NMW issue",
  ON_HOLD_ID_ISSUE: "On Hold – ID issue",
  ON_HOLD_LOW_EARNING_ISSUE: "On Hold – Low Earnings Limit",
};

const CANCELLATION_POP_UP_REASONS = {
  LEAVING_EMPLOYMENT: "Leaving or left employment",
  INSUFFICIENT_PAY:
    "Absent from work with insufficient pay (maternity, sick leave etc)",
};

const OTHER_ORGANISATION_OPTIONS = [
  { value: "yes", name: "Yes" },
  { value: "no", name: "No" },
];

const PLAN_STATUS_MAPPING : {[key: string]: string } = {
  "On Hold - NMW issue": "On Hold - NMW",
  "Awaiting Authorisation": "Awaiting",
  "On Hold – ID issue": "On Hold – ID",
  "On Hold – Low Earnings Limit": "On Hold – LEL",
  "Accepted": "Accepted",
  "Cancelled": "Cancelled"
};

export {
  PRODUCTS,
  PRODUCT_MAPPING,
  PERCETAGE_VALUE_REGEX,
  TITLES_FOR_APPLICANTS,
  AVAILABLE_GENDERS,
  FLOAT_VALUE_REGEX,
  ORGANISATION_DEFAULT_VALUES,
  APP_PATHS,
  PTU_PLAN_STATUS,
  CANCELLATION_POP_UP_REASONS,
  OTHER_ORGANISATION_OPTIONS,
  ORGANISATION_TYPE,
  PLAN_STATUS_MAPPING
};
